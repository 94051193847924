var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        { staticClass: "flex pb-4" },
        [
          _c("div", { staticClass: "flex-1 flex items-center" }, [
            _c(
              "a",
              {
                staticClass: "back-icon mr-3 flex items-center",
                on: { click: _vm.back },
              },
              [
                _c("a-icon", {
                  staticClass: "text-xl",
                  attrs: { type: "left" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "text-xl font-medium" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _vm._t("actions"),
        ],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }