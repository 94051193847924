<template>
  <a-card :bordered="false">
    <div class="flex pb-4">
      <div class="flex-1 flex items-center">
        <a class="back-icon mr-3 flex items-center" @click="back">
          <a-icon type="left" class=" text-xl"/>
        </a>
        <div class="text-xl font-medium">{{title}}</div>
      </div>
      <slot name="actions"></slot>
    </div>
    <slot></slot>
  </a-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data(){
    return{
      
    }
  },
  methods:{
    back(){
      this.$router.push('/production/manage')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>